import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useGameParamsContext } from "supercell-frontend-base/src/stores/gameParams";
import { useSnowplowContext } from "supercell-frontend-base/src/analytics/snowplow";
import { ClashRoyaleGameParams } from "../../validation/game-params";

import { getProps } from "./get-props";

import { ArticleView } from "./view";

type Params = { id: string; variant?: string; slug?: string };

export const ArticleController = () => {
    const { pathname: pagePath } = useLocation();
    const { gameParams } = useGameParamsContext<ClashRoyaleGameParams>();
    const { id, variant, slug } = useParams<Params>();
    const sendCommand = useSendCommand();
    const { pageView } = useSnowplowContext();
    const isolated =
        (variant && variant.toLowerCase() === "isolated") ||
        (slug && slug.toLowerCase() === "isolated");

    const track = (article) => {
        const { id: pageId, title: pageTitle } = article;

        pageView(pageTitle);
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pageId,
            pagePath,
            pageTitle,
        });
    };

    const props = {
        getProps: getProps(id, isolated, gameParams),
        id,
        track,
    };

    return <ArticleView {...props} />;
};
