import React from "react";

import { inboxParser } from "supercell-frontend-base/src/parsers/inbox";

import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { Article } from "supercell-frontend-base/src/components/article";

import styles from "./styles.modules.scss";

export const ArticleView = ({ id, getProps, track }) => (
    <div className={`article ${styles.article}`}>
        <Tab namespace="news" parser={inboxParser} id={id}>
            {({ data: { articles, globals } }) => {
                const { article, Link, prevLinkProps, nextLinkProps } =
                    getProps({
                        articles,
                        globals,
                    });

                track(article);

                return (
                    <Article
                        article={article}
                        Link={Link}
                        nextLinkProps={nextLinkProps}
                        prevLinkProps={prevLinkProps}
                    />
                );
            }}
        </Tab>
    </div>
);
